import React from 'react';
import './contact.scss';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Contact = ({ location }) => {
  return (
    <div id="gbi-contact">
      <Helmet>
        <title>GoodBeef Index Contact Page</title>
        <meta name="description" content="GoodBeef Index welcomes feedback.
          Get in touch and lets us know what you think" />
        <link rel="canonical"
          href={ `${window.location.origin}/${location.pathname}`} />
      </Helmet>
      <h1 className="title">Get in touch...</h1>
      <a className="email" href="mailto:info@goodbeefindex.org">
        info@goodbeefindex.org
      </a>
    </div>
  );
};

Contact.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default Contact;
